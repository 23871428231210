<template>
  <base-card>
    <v-card-text>
      <div>
        <v-row class="align-end mb-6">
          <v-col
            cols="12"
            sm="4"
            md="3"
            lg="2"
            offset-lg="2"
          >
            <v-text-field
              v-model="city"
              hide-details
              clearable
              :label="$t('address.city')"
            />
          </v-col>

          <v-col
            cols="12"
            sm="4"
            md="3"
            lg="2"
          >
            <v-text-field
              v-model="street"
              hide-details
              clearable
              :label="$t('address.streetName')"
            />
          </v-col>

          <v-col
            cols="12"
            sm="4"
            md="2"
          >
            <v-text-field
              v-model="streetNumber"
              hide-details
              clearable
              :label="$t('address.streetNumber')"
            />
          </v-col>

          <v-col
            cols="12"
            md="4"
            class="d-flex justify-center justify-md-start"
          >
            <v-btn
              class="primary px-4 mr-4"
              @click="searchAccesses()"
            >
              {{ $t("common.search") }}
            </v-btn>

            <v-btn
              class="info px-4 mr-4"
              @click="onAddAccessDetail()"
            >
              {{ $t("common.add") }}
            </v-btn>

            <v-btn
              class="error px-4"
              @click="resetForm">
              {{ $t("common.reset") }}
            </v-btn>
          </v-col>
        </v-row>

        <v-alert
          border="left"
          outlined
          type="error"
          elevation="2"
          class="mb-6 mt-2 text-left"
          dismissible
          text
          v-if="errorMessage"
        >
          {{ errorMessage }}
        </v-alert>
      </div>

      <v-data-table
        :headers="headers"
        :items="tableData"
        hide-default-footer
        item-key="id"
        class="eg-table-bnone eg-table-td-py-md mt-2 px-4 mainText--text"
      >
        <template v-slot:item.address="{item}">
          <span>{{ item.streetName }} {{ item.streetNumber }} {{ item.streetLittera }}</span>
          <br />
          <span>{{ util.formatPostalCode(item.postalCode) }} {{ item.city }}</span>
        </template>

        <template v-slot:item.services="{item}">
          <tag-group class="mt-1" :tags="item.services" />
        </template>

        <template v-slot:item.action="{item}">
          <v-btn
            class="ma-1"
            fab
            x-small
            color="success"
            @click.native="editAccess(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-actions class="justify-center">
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        color="primary"
        :total-visible="7"
        circle
      />
    </v-card-actions>

    <add-access-detail-dialog
      v-model="isShowNewAccessInfoDialog"
      @add-access-info="addAccessInfo"
    />

    <update-access-address-dialog
      v-model="isShowUpdateDialog"
      network-type="ip-only"
      :address="address"
      @refresh="refresh"
    />
  </base-card>
</template>

<script>
  import { mapActions } from 'vuex'

  import util from '@/utils'
  import { showErrorDialog } from '@/utils/swalDialog'

  import AddAccessDetailDialog from './AddAccessDetailDialog'
  import UpdateAccessAddressDialog from './UpdateAccessAddressDialog'
  import TagGroup from '../components/TagGroup'

  export default {
    metaInfo: {
      title: 'Search IP-Only Networks',
    },

    components: {
      TagGroup,
      AddAccessDetailDialog,
      UpdateAccessAddressDialog,
    },

    data () {
      return {
        tableData: [],
        limit: 10,
        currentPage: 1,
        totalPages: 1,
        headers: [],
        city: '',
        street: '',
        streetNumber: '',
        errorMessage: '',
        isShowNewAccessInfoDialog: false,
        address: {},
        isShowUpdateDialog: false,

        util: util,
      }
    },

    watch: {
      currentPage(val) {
        this.searchAccesses(val)
      }
    },

    mounted () {
      this.headers = [{
        text: this.$t('common.action'),
        value: 'action',
        width: 80,
        sortable: false,
      }, {
        text: this.$t('access.id'),
        value: 'accessId',
        width: 150,
      }, {
        text: this.$t('access.premises'),
        value: 'premisesType',
        width: 200,
      }, {
        text: this.$t('access.population'),
        value: 'population',
        width: 180,
      }, {
        text: this.$t('access.service'),
        value: 'services',
        width: 350,
      }, {
        text: this.$t('access.mduApartmentNumber'),
        value: 'mduApartmentNumber',
        width: 230,
      }, {
        text: this.$t('access.mduDistinguisher'),
        value: 'mduDistinguisher',
        width: 200,
      }, {
        text: this.$t('access.outlet'),
        value: 'outlet',
        width: 200,
      }, {
        text: this.$t('address.address'),
        value: 'address',
        width: 240,
      }]

      this.searchAccesses()
    },

    methods: {
      ...mapActions({
        getAccessesByAddress: 'getAccessesByAddress',
        updateAccessInfo: 'updateAccessInfo',
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
      }),

      async searchAccesses(currentPage = 1) {
        this.currentPage = currentPage
        this.errorMessage = ''

        this.setLoading(true)
        this.setLoadingText(`${this.$t('common.loading')} ${this.$t('common.data')}...`)
        try {
          const payload = {
            city: this.city,
            street: this.street,
            number: this.streetNumber,
            offset: (currentPage - 1) * this.limit,
            limit: this.limit,
            type: 'ip-only',
          }

          const response = await this.getAccessesByAddress(payload)

          this.totalPages = Math.ceil(response.count / this.limit)
          this.tableData = response.rows.map(item => {
            return {
              ...item,
              services: item.services.map(serviceItem => serviceItem.service)
            }
          })
        } catch (error) {
          this.totalPages = 1
          this.tableData = []
          this.currentPage = 1
          this.errorMessage = util.getErrorResponse(error)
        }
        this.setLoading(false)
      },

      onAddAccessDetail() {
        this.isShowNewAccessInfoDialog = true
      },

      async addAccessInfo(accessId) {
        this.isShowNewAccessInfoDialog = false

        this.setLoading(true)
        this.setLoadingText(`${this.$t('common.loading')} ${this.$t('common.data')}...`)
        try {
          const payload = {
            type: 'ipOnly',
            accessId,
          }

          const response = await this.updateAccessInfo(payload)
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.setLoading(false)
      },

      editAccess(access) {
        this.address = {
          id: access.id,
          streetName: access.streetName,
          streetNumber: access.streetNumber,
          streetLittera: access.streetLittera,
          city: access.city,
          postalCode: access.postalCode,
        }

        this.isShowUpdateDialog = true
      },

      resetForm() {
        this.errorMessage = ''
        this.city = ''
        this.street = ''
        this.streetNumber = ''
        this.tableData = []
        this.currentPage = 1
        this.totalPages = 1
      },

      refresh() {
        this.searchAccesses(this.currentPage)
      }
    },
  }
</script>
